import { RouteRecordRaw } from 'vue-router';
import { verifyAuth } from '../../middlewares'
const routes: Array<RouteRecordRaw> = [
    {
        path: '/homepage',
        name: 'Homepage',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "HomePage" */ './pages/Homepage.vue'),
    },
    {
        path: '/collection',
        name: 'Collection',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "Collection" */ './pages/Collection.vue'),
    },
    {
        path: '/merch',
        name: 'Merch',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "Merch" */ './pages/Merch.vue'),
    },
    {
        path: '/myaccount',
        name: 'MyAccount',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "Profile" */ './pages/MyAccount.vue'),
    },
    {
        path: '/checkout',
        name: 'Checkout',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "Checkout" */ './pages/Checkout.vue'),
    },
    {
        path: '/instructions',
        name: 'RewardClaimInstruction',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "Checkout" */ './pages/RewardClaimInstruction.vue'),
    },
    {
        path: '/campaign/:campaign_slug/:entry_code',
        name: 'HubGamificationCampaignPage',
        beforeEnter: [verifyAuth, async (to, from) => {
            to.meta['from_route'] = from.name;
         }],
        component: () => import(/* webpackChunkName: "HubGamificationCampaignPage" */ './pages/GamificationCampaignPage/GamificationCampaignPage.vue'),
        props: true,
    },
    {
        path: '/campaign-checkpoint/:campaign_slug',
        name: 'CampaignCheckpoint',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "ScratchCheckpoint" */ './pages/GamificationCampaignPage/CampaignCheckpoint.vue'),
    },
    {
        path: '/scratch-checkpoint/:id',
        name: 'ScratchCheckpoint',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "ScratchCheckpoint" */ './pages/ScratchAndWinCheckpoint.vue'),
    },
    {
        path: '/scratch-play/:id',
        name: 'ScratchPlay',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "ScratchPlay" */ './pages/ScratchAndWinPlay.vue'),
    },
    {
        path: '/scratch-complete/:id',
        name: 'ScratchComplete',
        beforeEnter: [verifyAuth],
        component: () => import(/* webpackChunkName: "ScratchComplete" */ './pages/ScratchAndWinComplete.vue'),
    },
]

export default routes;