import { readonly, ref, computed } from 'vue'
import { Rewards, Prizes, Transactions, CartItem, PrizeRedeemResponse, Campaign } from '../middlewares/auth/models'
import { useAuth } from './useAuth'
import { HttpClient } from '../utils';
import useAppSetup from './useAppSetup';
import i18n from '../i18n';
import GAnalytics from "../utils/GAnalytics"

const { authToken, authUser } = useAuth();
const { appToken } = useAppSetup();
const { t } = i18n.global;

const httpClient = new HttpClient(import.meta.env.VITE_AUTH_API_URL).httpClient;
const campaignHttpClient = new HttpClient(import.meta.env.VITE_GO_CONTENT_API_URL).httpClient;
const campaignToken = import.meta.env.VITE_GO_CONTENT_API_TOKEN

// const initSelectedReward: Rewards = {
//     id: 0,
//     name: '',
//     desc: '',
//     sku: '',
//     image_url: '',
//     total_qty: 0,
//     total_claimed: 0,
//     points_required: 0,
//     reward_type: '',
//     retail_value: 0,
//     shipping_required: false,
//     shipping_cost: 0,
//     sort_order: 0,
//     is_available: true,
// }

// const initSelectedPrize: Prizes = {
//     id: 0,
//     name: '',
//     desc: '',
//     image_url: '',
//     total_qty: 0,
//     total_claimed: 0,
//     points_required: 0,
//     provider: '',
//     config: {
//         locales: [
//             {
//                 locale: '',
//                 scratch_card_image: '',
//             }
//         ],
//         prizing_id: '',
//         reveal_type: '',
//     },
//     locales: null,
//     is_enabled: false,
// }

const initTransactions: Transactions = {
   total_results: 0,
   total_pages: 0,
   current_page: 0,
   per_page: 0,
   results: [{
      tx_key: '',
      points_earned: 0,
      points_redeemed: 0,
      created_at: new Date(),
      transaction_details:
         [{
            tx_type: 'earn',
            details: {
               tab: '',
               action: '',
            },
            points: 0,
            notes: '',
         }]
   }]
}
// const sampleCampaigns = [
// 	{
// 		id: 1220,
// 		campaign_slug: '12-days-uat-test-replica-39783',
// 		entry_code: 'test1',
// 		image_url: 'https://picsum.photos/200',
// 		name: 'sample name1',
// 		desc: '',
// 		campaign_url: '',
// 		points_required: 2
// 	}
// ]
const availableRewards = ref<Rewards[]>()
const availablePrizes = ref<Prizes[]>()
const availableCampaigns = ref<Campaign[]>()
const selectedReward = ref<Rewards | Prizes | Campaign>()
const selectedPrize = ref<Prizes>()
const transactions = ref<Transactions>(initTransactions)
const transactionsByPage = ref<Transactions>(initTransactions)
const cartItems = ref<CartItem[]>()

type shippingAddress = {
   shipping_address: {
      address_line_1: string;
      address_line_2: string | null;
      city: string;
      postal_code: string;
      province: string;
      country: string;
   }

}
type rewardPurchased = {
   reward_name: string;
   reward_code: string;
}

export function useRewards() {

   const setRewards = async () => {
      const result = await httpClient.get<{ payload: Rewards[] }>(`/v1/clients/loyalty/rewards`, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(result.msg);
      }
      availableRewards.value = result.payload
   }

   const setPrizes = async () => {
      const result = await httpClient.get<{ payload: Prizes[] }>(`/v1/clients/loyalty/prizes`, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(result.msg);
      }
      availablePrizes.value = result.payload
   }
   const setCampaigns = async () => {
      const result = await campaignHttpClient.get<{ payload: Campaign[] }>(`/v1/client/public/campaigns`, {
         headers: {
            'api-token': campaignToken,
            'auth-token': authToken.value,
            'api-secret': import.meta.env.VITE_GO_CONTENT_API_SECRET,
         }
      });
      if (result.error) {
         throw new Error(result.msg)
      }
      availableCampaigns.value = result.payload.filter(campaign => {
         const date = new Date()
         const startDate = new Date(campaign.start_date)
         const endDate = new Date(campaign.end_date)
         return campaign.campaign_slug && date >= startDate && date <= endDate
      })
   }
   const claimCampaign = async () => {
      const result = await httpClient.post(`/v1/clients/loyalty/gamification`, {
         campaign_slug: (selectedReward.value as Campaign).campaign_slug,
         entry_code: (selectedReward.value as Campaign).campaign_entry_code,
         points: (selectedReward.value as Campaign).loyalty_points_required
      },
         {
            headers: {
               'app-token': appToken.value,
               'auth-token': authToken.value,
               'Content-Type': 'application/json'
            }
         });
      console.log(result)
      if (result.error) {
         throw new Error(result.msg);
      }
      // return result
   }
   const addItemToCart = async (rewardId: number) => {
      const result = await httpClient.post<{}>(`/v1/clients/loyalty/cart/items`, {
         reward_id: rewardId
      }, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });
      if (result.error) {
         throw new Error(result.msg);
      }

      GAnalytics.logCustomEvent('add_to_cart', {
         reward_id: rewardId.toString(),
      })
      await getMyCart()
   }

   const redeemPrize = async (prizeId: number) => {
      const result = await httpClient.post<PrizeRedeemResponse>(`/v1/clients/loyalty/redeem/prize`, {
         prize_id: prizeId
      }, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });
      if (result.error) {
         throw new Error(result.msg);
      }

      console.log(result)
      let prize = {
         ...result.prize,
         isPrizeRevealed: false
      }
      console.log('request', prize.isPrizeRevealed)
      window.localStorage.setItem('redeemedPrize', JSON.stringify(prize))

   }

   const setSelectedReward = (reward: Rewards | Prizes | Campaign) => {
      selectedReward.value = reward
   }

   const setSelectedPrize = (prize: Prizes) => {
      selectedPrize.value = prize
   }

   const claimReward = async (keyword: string) => {
      const result = await httpClient.post<{}>(`/v1/clients/keywords/claim`, {
         keyword
      }, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });
      if (result.statusCode === 400) {
         throw new Error(t("entered_pin_invalid"));
      } else if (result.error) {
         throw new Error(t("generic_server_error"));
      }

      console.log(result)
   }

   const getTransactions = async (page: number = 1, limit: number = 100) => {
      const result = await httpClient.get<Transactions>(`/v1/clients/loyalty/transactions?page=${page}&limit=${limit}`, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(result.msg);
      }
      // Object.assign(transactionsByPage.value, result)
      transactionsByPage.value = { ...result }
      if (result.current_page === 1) {
         Object.assign(transactions.value, result)
      } else {
         let oldTransaction = transactions.value.results
         Object.assign(transactions.value, result)
         transactions.value.results = [...oldTransaction, ...result.results]
      }

   }

   const setCartItems = (data: CartItem[]) => {
      cartItems.value = data
   }


   const getMyCart = async () => {
      const result = await httpClient.get<{ payload: CartItem[] | [] }>(`/v1/clients/loyalty/cart`, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(result.msg);
      }

      if (result.payload.length > 0) {
         setCartItems(result.payload)
      } else if (result.payload.length === 0) {
         setCartItems([])
      }
   }

   const removeCartItem = async (cartItemId: number) => {
      const result = await httpClient.delete<{}>(`/v1/clients/loyalty/cart/items/${cartItemId
         }`, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(result.msg);
      }

      if (!cartItems.value) return
      const index = cartItems.value.findIndex(el => el.id === cartItemId)

      if (index > -1) {
         let newCartItems = [...cartItems.value]
         newCartItems.splice(index, 1)
         setCartItems(newCartItems)
      }
      GAnalytics.logCustomEvent('remove_item', {
         reward_id: cartItemId.toString(),
      })
   }
   const rewardCountInCart = (val: Prizes | Rewards) => {
      let temp = 0;
      if (!('sku' in val)) return 0;
      cartItems.value?.forEach((item) => {
         if (item.reward.sku == val.sku) { temp = item.qty }
      })
      return temp;
   }

   const updateCartItemQty = async (cartItemId: number, qty: number) => {
      const result = await httpClient.patch<{}>(`/v1/clients/loyalty/cart/items/${cartItemId}`, {
         qty
      }, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(result.msg);
      }
      if (!cartItems.value) return
      const index = cartItems.value.findIndex(el => el.id === cartItemId)
      let newCartItems: CartItem[] = [...cartItems.value]
      newCartItems[index].qty = qty
      setCartItems(newCartItems)
      GAnalytics.logCustomEvent('remove_from_cart', {
         reward_id: cartItemId.toString(),
      })
   }

   const rewardsPurchasedByUser = computed<rewardPurchased[]>(() => {
      let rewards: rewardPurchased[] = []
      transactions.value.results.forEach((transaction) => {
         transaction.transaction_details.forEach((tx_details) => {
            if (tx_details.tx_type === 'redeem' && tx_details.details.reward_code) {
               // let quantity = tx_details.details.qty
               let codeArr: string[] = tx_details.details.reward_code.split(',')
               codeArr.forEach(code => {
                  rewards.push({ reward_code: code, reward_name: tx_details.details.reward_name })
               })
            }
         })
      })
      return rewards;
   })

   const totalNoOfCartItems = computed<number>(() => {
      if (!cartItems.value) return 0

      let sum = 0
      cartItems.value.forEach(item => {
         sum += item.qty
      })
      return sum
   })

   const totalCartsPoints = computed<number>(() => {
      if (!cartItems.value) return 0
      let sum = 0
      cartItems.value.forEach(item => {
         sum += item.qty * item.reward?.points_required
      })
      return sum
   })

   const userCurrentPoints = computed<number>(() => {
      if (!cartItems.value) return authUser.points
      let totalCartsPoints = 0
      cartItems.value.forEach(item => {
         totalCartsPoints += item.qty * item.reward?.points_required
      })
      return authUser.points - totalCartsPoints
   })

   const checkout = async (data: {} | shippingAddress) => {
      const result = await httpClient.post<{}>(`/v1/clients/loyalty/cart/checkout`, data, {
         headers: {
            'app-token': appToken.value,
            'auth-token': authToken.value,
         }
      });

      if (result.error) {
         throw new Error(t('checkout_failed'));
      }
      await getMyCart()
   }

   return {
      setRewards,
      setPrizes,
      setCampaigns,
      setSelectedReward,
      setSelectedPrize,
      selectedReward,
      selectedPrize,
      availableRewards,
      availablePrizes,
      availableCampaigns,
      addItemToCart,
      redeemPrize,
      claimReward,
      transactions,
      getTransactions,
      transactionsByPage,
      getMyCart,
      setCartItems,
      cartItems,
      totalNoOfCartItems,
      totalCartsPoints,
      userCurrentPoints,
      removeCartItem,
      updateCartItemQty,
      checkout,
      rewardsPurchasedByUser,
      rewardCountInCart,
      claimCampaign
   }
}